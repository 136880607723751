import { ElNotification } from "element-plus";

export const state = () => ({
  loggedIn: false,
  user: null,
  token: null,
});
export const getters = {};
export const mutations = {
  setAuthenticated(state, auth) {
    state.loggedIn = auth.loggedIn;
    state.user = auth.user;
    state.token = auth.token;
  },
};
export const actions = {
  async login({ commit, dispatch }, credentials) {
    let auth = {
      loggedIn: false,
      user: null,
      token: null,
    };
    try {
      const api = useAxios(false);
      const response = await api.post("/auth/login", credentials);

      auth = {
        loggedIn: true,
        user: response.data.user,
        token: response.data.access_token,
      };
      commit("setAuthenticated", auth);
      return response;
    } catch (err) {
      commit("setAuthenticated", auth);
      throw err;
    }
  },
  async logout({ commit }) {
    let auth = {
      loggedIn: false,
      user: null,
      token: null,
    };
    try {
      const api = useAxios(false);
      const response = await api.post("/auth/logout");
      commit("setAuthenticated", auth);
      return response;
    } catch (err) {
      commit("setAuthenticated", auth);
      throw err;
    }
  },
  async getUserDetails({ commit, state, dispatch }) {
    let auth = {
      loggedIn: false,
      user: null,
      token: null,
    };
    try {

      const api = useAxios();
      let { $router, $root, $store } = useNuxtApp();
      const response = await api.get("/auth/user");
      auth = {
        loggedIn: true,
        user: response.data.user,
        token: state.token,
      };
      commit("setAuthenticated", auth);
      return "ok";
    } catch (err) {
      commit("setAuthenticated", auth);
      throw err;
    }
  },
};
