export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $store, $router } = useNuxtApp();
  if (
    $store.state.auth.loggedIn &&
    [
      "/auth/login",
      "/auth/login/",
    ].includes(to.path)
  ) {
    return $router.push("/");
  }

  if (!$store.state.auth.loggedIn && to.path !== "/auth/login") {
    return $router.push("/auth/login");
  }
});
