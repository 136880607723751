import {
  authVuexStore,
  indexVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      auth: {
        ...authVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore