import axios from "axios";
import { ElNotification } from "element-plus";

export default function useAxios() {
  const { $config, $store, $router } = useNuxtApp();
  const defaultUrl = $config.public.BASE_URL_API;

  const api = axios.create({
    baseURL: defaultUrl,
  });

  const token = $store.state.auth.token;
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        let auth = {
          loggedIn: false,
          user: null,
          token: null,
        };
        await $store.commit("auth/setAuthenticated", auth);
        $router.push("/auth/login");
      } else {
        ElNotification({
          title: "Error",
          message: "An error occurred",
          type: "error",
        });
      }
      return Promise.reject(error);
    }
  );

  return api;
}
