<template>
  <nav
    class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl"
  >
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <a class="nav-link menu-toggle" href="#"
              ><i class="ficon" data-feather="menu"></i
            ></a>
          </li>
        </ul>
      </div>
      <ul class="nav navbar-nav align-items-center ms-auto">
        <li class="nav-item dropdown dropdown-user">
          <a
            class="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="user-nav d-sm-flex d-none">
              <span class="user-name fw-bolder">{{
                $store.state.auth.user?.name
              }}</span
              ><span class="user-status">{{
                $store.state.auth.user?.rol.name
              }}</span>
            </div>
            <span class="avatar"
              ><img
                class="round"
                src="https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg"
                alt="avatar"
                height="40"
                width="40" /><span class="avatar-status-online"></span
            ></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown-user"
          >
            <a class="dropdown-item" href="#" v-if="false"
              ><i class="me-50" data-feather="user"></i> Profile</a
            >
            <div class="dropdown-divider" v-if="false"></div>
            <a class="dropdown-item" href="javascript:void(0)" @click="logout"
              ><i class="me-50" data-feather="power"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      window,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/auth/login");
        ElNotification({
          title: "See you soon",
          type: "success",
        });
      });
    },
  },
};
</script>
