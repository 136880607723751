<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">CHAT {{ $route.params.room }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-8 col-md-6">
          <div class="row">
            <div class="col">
              <button class="btn btn-outline-danger" @click="openModal">
                <i data-feather="slash"></i> Ban a word
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-outline-secondary"
                @click="openModalConfig"
              >
                <i data-feather="settings"></i> Config Room
                {{ $route.params.room }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="container chat-container mt-5" style="position: relative">
            <button
              v-if="showNewMessagesButton"
              @click="goToNewMessages"
              class="btn btn-outline-secondary btn-sm"
              style="
                position: absolute;
                z-index: 9999;
                bottom: 44px;
                right: 30px;
              "
            >
              Ir a mensajes nuevos
            </button>
            <div
              class="chat-box"
              id="chat-box"
              ref="chatBox"
              @scroll="handleScroll"
            >
              <div
                class="chat-message received"
                v-for="(message, i) in messages"
                :key="i + render"
              >
                <img
                  class="chat-avatar"
                  :style="`border: 1px solid ${nameToHexColor(
                    message.sender?.name
                  )} !important`"
                  :src="message.sender?.avatar"
                  alt=""
                />

                <span
                  class="username"
                  :style="`color: ${nameToHexColor(
                    message.sender?.name
                  )} !important`"
                  >{{ message.sender?.name }}</span
                >
                <span>
                  <button
                    v-if="message.sender?.id !== 'admin'"
                    class="btn btn-outline-secondary btn-sm"
                    @click="banUserToSocket(message.sender?.id)"
                  >
                    Ban
                  </button>
                </span>

                <div class="message-content">{{ message.content }}</div>
              </div>
            </div>
            <form @submit.prevent="sendMessage" class="mt-3" autocomplete="off">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="text"
                  name="password"
                  autocomplete="off"
                  id="new-text"
                  placeholder="Escribe un mensaje..."
                />
                <button class="btn btn-secondary" type="submit">
                  <i data-feather="send"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="banWord"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form class="modal-content" @submit.prevent="banWordToSocket">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Ban Word</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="text" class="form-label">Text to Ban</label>
              <input
                type="text"
                required
                v-model="word"
                class="form-control"
                placeholder="Input the text to ban"
                id="text"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">Ban</button>
        </div>
      </form>
    </div>
  </div>
  <div
    class="modal fade"
    id="configModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form class="modal-content" @submit.prevent="updateRoomToSocket">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Config Room {{ $route.params.room }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="seconds" class="form-label"
                >limit writing after so many seconds</label
              >
              <input
                type="tel"
                required
                v-model="roomDB.delay"
                class="form-control"
                placeholder="Insert seconds"
                id="seconds"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { Modal } from "bootstrap";

export default defineNuxtComponent({
  props: ["room"],
  data() {
    return {
      render: 0,
      roomDB: {
        delay: 0,
      },
      Modal: null,
      ModalConfig: null,
      tenorKey: "AIzaSyB9gngwRDOOiQA5a-BoeF07q44wN0L4tbY",
      socket: null,
      banned: false,
      text: "",
      word: "",
      messages: [],
      counter: 0,
      interval: null,
      autoScroll: true,
      showNewMessagesButton: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let modalElement = document.querySelector("#banWord");
      this.Modal = Modal.getOrCreateInstance(modalElement);
      modalElement.addEventListener("hidden.bs.modal", () => {
        this.word = "";
      });

      let modalElementConfig = document.querySelector("#configModal");
      this.ModalConfig = Modal.getOrCreateInstance(modalElementConfig);
      modalElementConfig.addEventListener("hidden.bs.modal", () => {});
    });
    this.connectSocket();
    this.scrollToBottom();
  },
  methods: {
    banWordToSocket() {
      if (this.word.length > 3) {
        this.socket.emit("banWord", {
          room: this.room,
          word: this.word,
        });
        this.closeModal();
      }
    },
    banUserToSocket(userId) {
      this.socket.emit("banUser", {
        room: this.room,
        userId,
      });
    },
    updateRoomToSocket() {
      if (this.roomDB.delay >= 0) {
        this.socket.emit("updateRoom", {
          room: this.room,
          delay: this.roomDB.delay,
        });
        this.closeModalConfig();
      }
    },
    closeModal() {
      this.Modal.hide();
    },
    openModalConfig() {
      this.ModalConfig.show();
    },
    closeModalConfig() {
      this.ModalConfig.hide();
    },
    openModal() {
      this.Modal.show();
    },
    getTimePassed(date) {
      var a = moment(date);
      var b = moment();
      return a.from(b);
    },
    nameToHexColor(name) {
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i++) {
        const value = (((hash >> (i * 8)) & 0xff) % 128) + 127;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return color;
    },
    sendMessage() {
      if (this.text) {
        this.socket.emit("sendMessage", {
          room: this.room,
          data: {
            sender: {
              avatar:
                "https://d2icuchxdp00d0.cloudfront.net/img/avatar-2021-v1.png",
              name: "Admin Juegaenlinea",
              id: "admin",
            },
            content: this.text,
          },
        });
        this.text = "";
      }
    },
    joinRoom() {
      this.socket.emit("joinRoom", this.room);
    },
    connectSocket() {
      const { $io } = useNuxtApp();
      if (this.socket !== null) {
        this.socket.off();
      }
      this.socket = $io(this.$config.public.WS_URL, {
        transports: ["websocket"],
      });
      this.socket.on("connect", () => {
        this.joinRoom();
      });
      this.socket.on("receiveMessage", (payload) => {
        this.messages.push(payload);
        this.render++;
        this.$nextTick(() => {
          if (this.autoScroll) {
            this.scrollToBottom();
          } else {
            this.showNewMessagesButton = true;
          }
        });
      });
      this.socket.on("userBanned", ({ userId }) => {
        this.messages = this.messages.filter((m) => m.sender?.id !== userId);
      });
      this.socket.on("errorMessage", ({ message, type }) => {
        if (type == "banned") {
          this.banned = true;
        }
        ElNotification({
          title: "Mensaje no enviado",
          message,
          type: "warning",
        });
      });
      this.socket.on("joinedRoom", (e) => {
        this.roomDB = e.roomdb;
      });
      this.socket.on("error", (e) => {});
      this.socket.on("disconnect", (e) => {});
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    },
    handleScroll() {
      const chatBox = this.$refs.chatBox;
      if (chatBox) {
        const threshold = 100;
        if (
          chatBox.scrollTop + chatBox.clientHeight + threshold <
          chatBox.scrollHeight
        ) {
          this.autoScroll = false;
        } else {
          this.autoScroll = true;
          this.showNewMessagesButton = false;
        }
      }
    },
    goToNewMessages() {
      this.autoScroll = true;
      this.showNewMessagesButton = false;
      this.scrollToBottom();
    },
  },
});
</script>

<style>
.chat-avatar {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 10px;
}
.chat-container {
  max-width: 400px;
  margin: auto;
}
.chat-box {
  height: calc(100vh - 350px);
  overflow-y: scroll;
  padding: 10px;
  width: 100%;
}
.chat-message {
  margin-bottom: 5px;
}
.chat-message .message-content {
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  word-wrap: break-word;
  font-size: 13px;
}
.chat-message .message-content {
  width: 100%;
}
.chat-message .username {
  vertical-align: top;
  font-weight: bold;
  margin-right: 10px;
}
.chat-message.sent .username {
  color: #0d6efd;
}
.chat-message.received .username {
  color: #dc3545;
}
</style>
