import { default as affiliatesiMTohQ8vAHMeta } from "/var/www/admin-calimaco/pages/affiliates.vue?macro=true";
import { default as logind9Ife3IeVAMeta } from "/var/www/admin-calimaco/pages/auth/login.vue?macro=true";
import { default as _91room_93RRr0GCydzdMeta } from "/var/www/admin-calimaco/pages/chats/[room].vue?macro=true";
import { default as indexgLZ3SK2SGRMeta } from "/var/www/admin-calimaco/pages/chats/index.vue?macro=true";
import { default as index5nGBV6hQgJMeta } from "/var/www/admin-calimaco/pages/index.vue?macro=true";
export default [
  {
    name: affiliatesiMTohQ8vAHMeta?.name ?? "affiliates",
    path: affiliatesiMTohQ8vAHMeta?.path ?? "/affiliates",
    meta: affiliatesiMTohQ8vAHMeta || {},
    alias: affiliatesiMTohQ8vAHMeta?.alias || [],
    redirect: affiliatesiMTohQ8vAHMeta?.redirect || undefined,
    component: () => import("/var/www/admin-calimaco/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: logind9Ife3IeVAMeta?.name ?? "auth-login",
    path: logind9Ife3IeVAMeta?.path ?? "/auth/login",
    meta: logind9Ife3IeVAMeta || {},
    alias: logind9Ife3IeVAMeta?.alias || [],
    redirect: logind9Ife3IeVAMeta?.redirect || undefined,
    component: () => import("/var/www/admin-calimaco/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91room_93RRr0GCydzdMeta?.name ?? "chats-room",
    path: _91room_93RRr0GCydzdMeta?.path ?? "/chats/:room()",
    meta: _91room_93RRr0GCydzdMeta || {},
    alias: _91room_93RRr0GCydzdMeta?.alias || [],
    redirect: _91room_93RRr0GCydzdMeta?.redirect || undefined,
    component: () => import("/var/www/admin-calimaco/pages/chats/[room].vue").then(m => m.default || m)
  },
  {
    name: indexgLZ3SK2SGRMeta?.name ?? "chats",
    path: indexgLZ3SK2SGRMeta?.path ?? "/chats",
    meta: indexgLZ3SK2SGRMeta || {},
    alias: indexgLZ3SK2SGRMeta?.alias || [],
    redirect: indexgLZ3SK2SGRMeta?.redirect || undefined,
    component: () => import("/var/www/admin-calimaco/pages/chats/index.vue").then(m => m.default || m)
  },
  {
    name: index5nGBV6hQgJMeta?.name ?? "index",
    path: index5nGBV6hQgJMeta?.path ?? "/",
    meta: index5nGBV6hQgJMeta || {},
    alias: index5nGBV6hQgJMeta?.alias || [],
    redirect: index5nGBV6hQgJMeta?.redirect || undefined,
    component: () => import("/var/www/admin-calimaco/pages/index.vue").then(m => m.default || m)
  }
]