import VuexPersistence from "vuex-persist";
import Roles from "../roles";

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
  modules: ["auth"],
});

export const state = () => ({
  menuItems: [
    {
      label: "Home",
      icon: "home",
      link: "/",
      roles: [Roles.ROOT],
    },
    {
      label: "Affiliates",
      icon: "users",
      link: "/affiliates",
      roles: [Roles.ROOT],
    },
    {
      label: "Moderate Chats",
      icon: "message-circle",
      link: "/chats",
      roles: [Roles.ROOT],
    },
  ],
  loadingSvg: `
  <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
  `,
  loading: false,
});

export const mutations = {
  setLoadingStatus(state, status) {
    state.loading = status;
  },
};
export const actions = {
  async toggleLoading({ commit }, payload) {
    commit("setLoadingStatus", payload);
  },
};

export const getters = {};

export const plugins = [vuexPersist.plugin];
